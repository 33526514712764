import { Suspense, lazy, useState } from 'react';
import { Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';
import ToolBar from '@mui/material/Toolbar';

import withRoot from '../../wiring/with-root';
import themer from '../../styles/material-theme';
import colors from '../../styles/colors';

const Palette = lazy(() => import('../../routes/styleguide/palette'));
const Buttons = lazy(() => import('../../routes/styleguide/buttons'));
const Inputs = lazy(() => import('../../routes/styleguide/inputs'));
const Alerts = lazy(() => import('../../routes/styleguide/alerts'));

const a11yProps = (index) => ({
    id: `styleguide-tab-${index}`,
    'aria-controls': `styleguide-panel-${index}`,
});

const StyleguideLayout = () => {
    const [activeTab, setActiveTab] = useState(0);

    const handleChange = (_e, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Suspense fallback={<div />}>
            <AppBar component="header" position="static" color="secondary">
                <ToolBar
                    sx={{
                        justifyContent: 'right',
                        '& a': {
                            color: 'secondary.contrastText',
                            textDecoration: 'none',
                            fontFamily: '"IBM Plex Sans", sans-serif',
                            fontWeight: 500,
                        },
                    }}
                >
                    <Link to="/login">
                        Log In
                    </Link>
                </ToolBar>
            </AppBar>
            <main>
                <Grid container minHeight="100vh">
                    <Grid item xs={4} sm={3} md={2} sx={{ borderRight: `1px solid ${colors.grey}` }}>
                        <Tabs
                            orientation="vertical"
                            value={activeTab}
                            onChange={handleChange}
                            variant="fullWidth"
                            TabIndicatorProps={{
                                style: { display: 'none' },
                            }}
                        >
                            <Tab disableRipple label="Colors &amp; Typography" {...a11yProps(0)} />
                            <Tab disableRipple label="Buttons &amp; Links" {...a11yProps(1)} />
                            <Tab disableRipple label="Inputs" {...a11yProps(2)} />
                            <Tab disableRipple label="Alerts" {...a11yProps(3)} />
                        </Tabs>
                    </Grid>
                    <Grid item xs={8} sm={9} md={10} sx={{ backgroundColor: colors.white }}>
                        <Container>
                            {activeTab === 0 && <Palette />}
                            {activeTab === 1 && <Buttons />}
                            {activeTab === 2 && <Inputs />}
                            {activeTab === 3 && <Alerts />}
                        </Container>
                    </Grid>
                </Grid>
            </main>
        </Suspense>
    );
};

export default withRoot(StyleguideLayout, themer);
