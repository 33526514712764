export default {
    white: '#ffffff',
    whiteDark: '#F4F4F5',
    black: '#2F3135',
    grey: '#4B5966',
    greyDark: '#6C6E71',
    greyDarker: '#0F2032',
    greyLight: '#CFDAE6',
    greyLightest: '#F0F4F7',
    regalBlue: '#1D2E4B',
    tAndPink: '#E60073',
    brightPink: '#FF0080',
    midnight: '#16324D',
    midnightLight: '#162F4A',
    error: '#E00E0E',
    errorRGB: '224, 14, 14',
    blue: '#0080FF',
    blueRGB: '0, 128, 255',
    success: '#02FF7F',
    successRGB: '2, 255, 127',
};
