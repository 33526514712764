import { combineReducers } from 'redux';
import Auth from './auth';
import User from './user';

const Reducers = () => combineReducers({
    auth: Auth,
    user: User,
});

export default Reducers;
