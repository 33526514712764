import { lazy, Suspense } from 'react';
import { Route } from 'react-router-dom';
import PublicLayout from '../layouts/public';
import StyleguideLayout from '../layouts/styleguide';
import { SentryRoutes } from '../utils/sentry';

const AdminLayout = lazy(() => import('../layouts/admin'));

const App = () => (
    <Suspense fallback={<div />}>
        <SentryRoutes>
            {/* Remove this route for new applications */}
            <Route path="/styleguide/*" element={<StyleguideLayout />} />
            <Route path="/admin/*" element={<AdminLayout />} />
            <Route path="/*" element={<PublicLayout />} />
        </SentryRoutes>
    </Suspense>
);

export default App;
